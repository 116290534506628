@import '../../assets/variables.scss';

.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  background-color: $grayBackground;
  width: 100%;
  align-items: center;
  background-color: white;

  .page {
    flex: 1;
    display: flex;
    flex-direction: column;

    .pageTitle {
      font-size: 24px;
      font-weight: bold;
      line-height: 36px;
      margin-bottom: 48px;
      margin-top: 85px;
    }

    .title {
      p {
        font-size: 18px;
        font-weight: 700;
        padding-bottom: 13px;
      }
    }

    .accountType {
      width: 100%;
      height: 195px;
      margin-bottom: 71px;

      display: flex;

      .illustration {
        padding: 13px 24px;
        width: 264px;

        h1 {
          font-weight: 800;
          font-size: 36px;
          color: white;
        }
      }

      .content {
        flex: 1;
        background-color: #f2f2f2;
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .points {
          p {
            font-size: 16px;
            line-height: 28px;
            font-family: 'Public Sans';
          }
        }

        .barContainer {
          .textBar {
            display: flex;
            justify-content: space-between;
            p {
              font-weight: bold;
              font-size: 13px;
            }
            padding-bottom: 5px;
          }
        }
      }
    }

    .container {
      width: 936px;
      background-color: #f2f2f2;
      display: flex;
      .illustrationContainer {
        width: 352px;
        min-height: 633px;
        background-color: $main;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .logoContainer {
          display: flex;
          justify-content: center;
          padding-top: 67px;
          padding-bottom: 71px;

          & > img {
            width: 110px;
          }
        }

        .illustration {
          padding-bottom: 21px;
        }
      }

      .formContainer {
        padding: 24px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .form {
          .header {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 8px;
            line-height: 36px;
          }

          .subheader {
            max-width: 400px;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 24px;
          }

          .row {
            flex: 1;
            display: flex;
            justify-content: space-between;
            margin-bottom: 17px;
            .shortInput {
              width: 422px;
            }

            .select {
              div > div {
                background-color: white;
              }
            }

            .input {
              background-color: white;
            }
          }

          .requiredDisclaimer {
            font-size: 12px;
            line-height: 16px;
          }
        }

        .formActions {
          margin-top: 40px;
          height: 48px;
          display: flex;
          justify-content: flex-start;

          .submit {
            font-size: 16px;
            padding-left: 15.18px;
            padding-right: 15.18px;
          }
        }
      }

      margin-bottom: 40px;
    }
  }
}

.pro {
  background-color: $orange;
}

.free {
  background-color: $blue;
}

.buttons {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 1200px) {
  .root {
    .page {
      .container {
        width: 700px;

        .formContainer {
          .form {
            .row {
              .shortInput {
                width: 310px;
              }
            }
          }
        }
      }
    }
  }
}
