@import 'carbon-components/scss/globals/scss/styles.scss';
@import './assets/variables.scss';
// Use the gray 10 theme
$carbon--theme: $carbon--theme--g10;
@include carbon--theme();

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bx--search-close {
  display: none;
}

.bx--data-table tbody tr,
.bx--data-table tbody tr td,
.bx--data-table tbody tr th {
  background-color: #ffffff;
}

.bx--data-table td,
.bx--data-table tbody th {
  border-top: 0px;
  border-bottom: 0px;
}

.bx--data-table--sticky-header
  th:not(.bx--table-column-checkbox):not(.bx--table-column-menu):not(.bx--table-expand-v2):not(.bx--table-column-icon),
.bx--data-table--sticky-header
  td:not(.bx--table-column-checkbox):not(.bx--table-column-menu):not(.bx--table-expand-v2):not(.bx--table-column-icon) {
  width: 100%;
  min-width: 0;
  display: block;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 10px);
  padding-top: 0.9375rem;
  padding-bottom: 1rem;
  overflow-y: hidden;
}

.bx--data-table--sticky-header thead,
.bx--data-table--sticky-header tbody,
.bx--data-table--sticky-header tr,
.bx--data-table--sticky-header th,
.bx--data-table--sticky-header td {
  display: flex;
  width: 100%;
}

.bx--tag--high-contrast {
  display: none;
}
.bx--btn--tertiary {
  border-radius: 6px;
  border-color: black;
  color: black;
}
.costum-class {
  width: 48px;
  padding-right: 5px;
  margin-top: 15px;
  text-align: center;
  padding: 2px;
  padding-block-end: 2px;
  padding-block-start: 2px;
  padding-inline-start: 23px;
}
.bx--btn--tertiary:hover {
  background-color: tra;
}
.bx--tab-content {
  padding: 0;
  width: 100%;
  margin: 0 auto;

  @media (min-width: 800px) {
    width: 846px;
  }
}
.bx--file__selected-file {
  background-color: #ffffff;
}
.bx--list-box__label {
  color: #000035;
}
.bx--btn--ghost {
  border-width: 2px;
  border-style: solid;
  border-color: #000035;
  background-color: transparent;
  color: #000035;
  padding: calc(0.875rem - 3px) 16px;
}
.CSVImport_root__36eoU p {
  font-size: 15px;
}
.bx--tabs--scrollable.bx--tabs--scrollable--container {
  max-width: 846px;
  margin: 0 auto;
}
.bx--tabs--scrollable.bx--tabs--scrollable--container
  .bx--tabs--scrollable__nav-item
  .bx--tabs--scrollable__nav-link {
  border-bottom: none;

  @media (min-width: 800px) {
    width: 423px;
  }
}
.bx--search--xl .bx--search-input,
.bx--search--xl.bx--search--expandable.bx--search--expanded .bx--search-input {
  height: 3rem;
  padding: 0 3rem;
  background-color: white;
}
.bx--data-table--sticky-header {
  max-height: 28.75rem;
}
.bx--btn--ghost.bx--btn--sm {
  padding: calc(0.375rem - 3px) 16px;
  border-radius: 6px;
  color: $main;
  border-color: $main;
  border: 1px solid;
  padding-inline-start: 14px;
}
element.style {
  max-height: 26rem;
}

.bx--file__selected-file {
  width: 19rem;
  max-width: 19rem;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bx--modal-container .bx--modal-header,
.bx--modal-container .bx--modal-content,
.bx--modal-container .bx--modal-content__regular-content {
  margin-bottom: 0rem;
}
.bx--tooltip__label {
  color: #000035;
}
.bx--label {
  color: #000035;
}
.bx--btn--field,
.bx--btn--md {
  padding: calc(0.675rem - 2px) 13px calc(0.675rem - 3px) 10px;
}
.CSVImport_root__36eoU .CSVImport_btnImportCSV__2phFL {
  margin-top: 14px;
  width: 105px;
  height: 0px;
}
.bx--btn:disabled,
.bx--btn:hover:disabled,
.bx--btn:focus:disabled,
.bx--btn.bx--btn--disabled,
.bx--btn.bx--btn--disabled:hover,
.bx--btn.bx--btn--disabled:focus {
  color: rgba(0, 0, 53, 0.5);
  background: #e6e6eb;
  border-color: white;
}
.bx--btn--ghost:hover {
  background-color: $orange;
  border-color: white;
  color: $main;
}
.bx--btn--ghost:active {
  background-color: $darkerorange;
  border-color: white;
  color: $main;
}
.bx--btn--primary {
  background-color: $neon;
  color: $main;
}
.bx--btn--primary:hover {
  background-color: $darkerneon;
  color: $main;
}
.bx--btn--primary:active {
  background-color: $deepdarkerneon;
  color: $main;
}
.bx--btn-set .bx--btn:not(.bx--btn--expressive) {
  max-width: 14.25rem;
}
.bx--modal-container {
  max-height: 90%;
  background: #ffffff;
  width: 584px;
  height: 556px;
}
.bx--file__selected-file {
  background-color: #f4f4f4;
}
.bx--modal-header__heading {
  font-weight: bold;
  padding-bottom: 20px;
  color: $main;
  font-family: 'Poppins';
  font-style: normal;
  font-size: 24px;
  line-height: 36px;
  position: absolute;
  top: 20px;
  left: 26px;
}
// .bx--btn-set .bx--btn {
//   position: absolute;
//   top: 481px;
//   height: 48px;
//   max-width: 14.25rem;
//   left: -230px;
// }
.bx--modal-container .bx--modal-header,
.bx--modal-container .bx--modal-content,
.bx--modal-container .bx--modal-content__regular-content {
  margin-bottom: 2rem;
}

.bx--modal-container .bx--modal-content,
.bx--modal-container .bx--modal-content__regular-content {
  margin-bottom: 10px;
}
.bx--data-table--sticky-header {
  display: block;
  overflow-y: auto;
  /* width: 100%; */
}
