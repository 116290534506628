@import '../../assets/variables.scss';

.root {
  width: 100%;
  padding: 27px 20px 20px;
  background-color: #ffffff;
  position: relative;

  h3 {
    font-weight: bold;
    padding-bottom: 20px;
    color: $main;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 24px;
    line-height: 36px;
  }
  p {
    font-size: 15px;
    color: $main;
    font-family: 'Public Sans';
  }
  .searchContainer {
    padding: 20px 0 0 0;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .searchInput {
      margin-right: 40px;
      max-width: 620px;

      @media (max-width: 768px) {
        margin-bottom: 15px;
      }
    }
  }
  .multiSearchContainer {
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    .container {
      max-width: 680px;
      display: flex;
      align-items: flex-end;

      .searchInput {
        margin-right: 40px;
        max-width: 620px;
      }
      .deleteBtn {
        margin-left: 20px;
        display: flex;
        align-items: center;
        max-width: 20px;
        max-height: 20px;
        cursor: pointer;
        border: none;
        color: black;
        padding: 0;
      }
    }
  }
  .error {
    padding-top: 20px;
    color: red;
  }
  .btn {
    padding-top: 10px;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 20rem;
    height: 4.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding-right: 15px;
  }
  .btnFindHsCode {
    margin-top: 40px;
  }
  .description {
    color: #393939;
    font-size: 10px;
    position: absolute;
    left: 40px;
    bottom: 30px;
  }
  .supportText {
    padding: 0;
    color: #393939;
    font-size: 10px;
    position: absolute;
    left: 20px;
    bottom: 12px;

    @media (max-width: 767px) {
      bottom: -30px;
    }

    a {
      color: $neon;
      &:hover {
        text-decoration: underline;
        color: $neon;
      }
    }
  }
}

.space {
  display: flex;
  flex-direction: column;
}

.multiSelect {
  width: 300px;
  margin-top: 30px;
}
.buttons {
  display: flex;
  flex-direction: row;
}
.tableContainer {
  display: flex;
  flex-direction: column;
}
.findBtn {
  margin-top: 30px;
}
.underlineContainer {
  padding: 10px 12px 0px 0px;
  display: flex;
  align-items: center;
}
