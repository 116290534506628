@import '../../../assets/variables.scss';
.root {
  padding: 27px 20px 20px;
  background-color: #ffffff;
  position: relative;
  width: 760px;
  h3 {
    font-weight: bold;
    padding-bottom: 20px;
    color: $main;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 24px;
    line-height: 36px;
  }
  p {
    font-size: 15px;
    color: $main;
    font-family: 'Public Sans';
  }
  .formContainer {
    padding-top: 40px;
  }
  .fileNameInput {
    width: 300px;
  }
  .btnImportCSV {
    margin-top: 40px;
    width: 92px;
  }

  .supportText {
    padding: 20px 0 0;
    color: #393939;
    font-size: 10px;
    // position: absolute;

    @media (max-width: 767px) {
      bottom: -30px;
    }

    a {
      color: #0353e9;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.fileUploadContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}
.fileContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  padding-top: 20px;
  .fileUploaderItem {
    margin-right: 10px;
    max-width: 20rem;
    min-height: 4.2rem;
  }
}
.renameFile {
  position: relative;
  padding-right: 30px;
  &:after {
    position: absolute;
    top: 20px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
.multiSelect {
  width: 300px;
}
.error {
  padding-top: 20px;
  color: red;
}
.buttons {
  display: flex;
  flex-direction: row;
}
.fileUploadTitle {
  color: $main;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.29;
  letter-spacing: 0.16px;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}
.fileUploadDescription {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.29;
  letter-spacing: 0.16px;
  margin-bottom: 1rem;
  color: #525252;
}

.toolTipButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: -15px;
  .buttonNextStep {
    margin-right: 10px;
    max-width: 20rem;
    min-height: 4.2rem;
    padding-top: 20px;
  }
}
a {
  color: #ffffff;
  text-decoration: none;
  max-width: stretch;
  word-wrap: break-word;
}
.link {
  text-decoration: none !important;
  color: $main;
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
}
.link:hover{
  color:$main;
}
.link:active{
  color:$main;
}
.icon{
  margin-left: 10px;
}